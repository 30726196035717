.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
}

.header {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  height: 70px;
  width: auto;
}

.app-name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.studio-name {
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
  color: #666;
}

.delete-account-card {
  width: 100%;
  max-width: 500px;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.title {
  margin-bottom: 16px;
}

.description {
  display: block;
  margin-bottom: 24px;
  color: #666;
  text-align: justify;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input {
  width: 100%;
}

.submit-button {
  width: 100%;
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}

.submit-button:hover {
  background-color: #ff7875;
  border-color: #ff7875;
}

@media (max-width: 480px) {

  .delete-account-card {
      padding: 16px;
  }

  .title {
      font-size: 20px;
  }

  .description {
      font-size: 14px;
      text-align: justify;
  }
}